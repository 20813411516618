.MuiContainer-root{
 min-width: 85vw !important;
}

.hover:hover{
    cursor: pointer;
}


.starred-bmp:hover{
    cursor: pointer;

}